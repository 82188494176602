import { FailureModeSectionDataP712 } from './Data/P712/P712.data';
import { FailureModeSectionDataP711 } from './Data/P711/P711.data';
import { FailureModeSectionDataP74 } from './Data/P74/P74.data';
import { FailureModeSectionDataP611 } from './Data/P611/P611.data';
import { FailureModeSectionDataP733P620 } from './Data/P733/P733.data';
import { FailureModeSectionDataP726 } from './Data/P726/P726.data';
import { FailureModeSectionDataP6142 } from './Data/P6142/P6142.data';
import { FailureModeSectionDataP716 } from './Data/P716/P716.data';
import { FailureModeSectionDataP77P65P652P831 } from './Data/P77/P77.data';
import { FailureModeSectionDataP76P610P727 } from './Data/P76/P76.data';
import { FailureModeSectionDataP722 } from './Data/P722/P722.data';
import { FailureModeSectionDataP615 } from './Data/P615/P615.data';
import { FailureModeSectionDataP66 } from './Data/P66/P66.data';
import { FailureModeSectionDataS72 } from './Data/S72/S72.data';
import { FailureModeSectionDataS71 } from './Data/S71/S71.data';
import { FailureModeSectionDataM42 } from './Data/M42/M42.data';

export const selectFailureModeSectionData = (selectedTag, formName, formData) => {
    const formNameToFunctionMap = {
        'P7-11': () => FailureModeSectionDataP711(formData),
        'P8-13.1': () => FailureModeSectionDataP711(formData),
        'P7-4': () => FailureModeSectionDataP74(selectedTag, formData),
        'P7-4.1': () => FailureModeSectionDataP74(selectedTag, formData),
        'P7-24': () => FailureModeSectionDataP74(selectedTag, formData),
        'P7-28': () => FailureModeSectionDataP74(selectedTag, formData),
        'P7-12': () => FailureModeSectionDataP712(selectedTag, formData),
        'P7-8': () => FailureModeSectionDataP712(selectedTag, formData),
        'P7-26': () => FailureModeSectionDataP726(formName, formData),
        'P7-25': () => FailureModeSectionDataP726(formName, formData),
        'M7-1': () => FailureModeSectionDataP726(formName, formData),
        'M6-11': () => FailureModeSectionDataP726(formName, formData),
        'M6-14': () => FailureModeSectionDataP726(formName, formData),
        'M6-15': () => FailureModeSectionDataP726(formName, formData),
        'M6-1': () => FailureModeSectionDataP726(formName, formData),
        'P6-11': () => FailureModeSectionDataP611(formData),
        'P7-17': () => FailureModeSectionDataP611(formData),
        'P7-33': () => FailureModeSectionDataP733P620(formData),
        'P6-20': () => FailureModeSectionDataP733P620(formData),
        'P6-14.2': () => FailureModeSectionDataP6142(formData),
        'P6-14': () => FailureModeSectionDataP6142(formData),
        'P7-31': () => FailureModeSectionDataP6142(formData),
        'P6-23.2': () => FailureModeSectionDataP6142(formData),
        'P6-15': () => FailureModeSectionDataP615(formData),
        'P7-16': () => FailureModeSectionDataP716(formData),
        'P6-13': () => FailureModeSectionDataP716(formData),
        'P7-7': () => FailureModeSectionDataP77P65P652P831(formData),
        'P6-5': () => FailureModeSectionDataP77P65P652P831(formData),
        'P6-5.2': () => FailureModeSectionDataP77P65P652P831(formData),
        'P8-3.1': () => FailureModeSectionDataP77P65P652P831(formData),
        'P7-6': () => FailureModeSectionDataP76P610P727(formData),
        'P6-10': () => FailureModeSectionDataP76P610P727(formData),
        'P7-27': () => FailureModeSectionDataP76P610P727(formData),
        'P7-22': () => FailureModeSectionDataP722(selectedTag, formData, formName),
        'P7-15': () => FailureModeSectionDataP722(selectedTag, formData, formName),
        'P7-14': () => FailureModeSectionDataP722(selectedTag, formData, formName),
        'P7-18': () => FailureModeSectionDataP722(selectedTag, formData, formName),
        'P7-29': () => FailureModeSectionDataP722(selectedTag, formData, formName),
        'P7-30': () => FailureModeSectionDataP722(selectedTag, formData, formName),
        'P6-6': () => FailureModeSectionDataP66(formData),
        'P8-17.1': () => FailureModeSectionDataP66(formData),
        'S7-2': () => FailureModeSectionDataS72(formData),
        'S7-3': () => FailureModeSectionDataS72(formData),
        'S7-4': () => FailureModeSectionDataS72(formData),
        'P6-25': () => FailureModeSectionDataS72(formData),
        'P6-30': () => FailureModeSectionDataS72(formData),
        'M4-2': () => FailureModeSectionDataM42(formData)
    };
    let filterViewData = { rows: [] };
    const functionToExecute = formNameToFunctionMap[formName];
    if (functionToExecute) {
        filterViewData = functionToExecute();
    } else if (['S7-1'].includes(formName) && formData.F956TrfTypeSelection === 'Partial') {
        return FailureModeSectionDataS71(formData);
    }
    return filterViewData;
};
