import { checkURVGreaterThanLRV } from 'helpers/Validation';
import { HMISectionDataP77 } from './Data/P77/P77.data';
import { HMISectionDataP78P617P712 } from './Data/P78/P78.data';
import { HMISectionDataP710P68, labelWidth } from './Data/P710/P710.data';
import { HMISectionDataP61 } from './Data/P61/P61.data';
import { HMISectionDataP66 } from './Data/P66/P66.data';
import { HMISectionDataP861andP69 } from './Data/P69/P69.data';
import { HMISectionDataP851 } from './Data/P851/P851.data';
import { HMISectionDataM613 } from './Data/M613/M613.data';
import { HMISectionDataM68 } from './Data/M68/M68.data';
import { HMISectionDataM611 } from './Data/M611/M611.data';
import { HMISectionDataMseries } from './Data/M63/M63.data';
import { HMISectionDataP610P76P716P613 } from './Data/P610/P610.data';
import { HMISectionDataM31P723 } from './Data/M31/M31.data';
import { HMISectionCulatedRangeFormula } from './Data/P78/P78.formulas';
import { HMISectionDataP74 } from './Data/P74/P74.data';
import { HMISectionDataC11C13C132C14, paddingLabel } from './Data/C11/C11.data';
import { HMISectionDataP615 } from './Data/P615/P615.data';
import { HMISectionDataP732 } from './Data/P732/P732.data';
import { HMISectionDataP891 } from './Data/P891/P891.data';
import { HMISectionDataP733P620P611P717 } from './Data/P733/P733.data';
import { HMISectionDataP726 } from './Data/P726/P726.data';
import { HMISectionDataP725 } from './Data/P725/P725.data';
import { HMISectionDataM616 } from './Data/M616/M616.data';
import { HMISectionDataM71 } from './Data/M71/M71.data';
import { HMISectionDataP720 } from './Data/P720/P720.data';
import { HMISectionDataP721 } from './Data/P721/P721.data';
import { HMISectionDataP711 } from './Data/P711/P711.data';
import { HMISectionDataP8141 } from './Data/P8141/P8141.data';
import { EngineeringUnit } from '../../Sections.constants';
import { HMISectionDataS72 } from './Data/S72/S72.data';
import { ValveSectionDataS71 } from './Data/S71/S71.data';
import { HMISectionDataS73 } from './Data/S73/S73.data';
import { HMISectionDataP625 } from './Data/P625/P625.data';

const hmiReadingLabel = 'HMI Reading Prior to Test';
const processGaugeLabel = 'Process Gauge Reading Prior to Test';
const lrvLabel = 'Instrument LRV';
const urvLabel = 'Instrument URV';
const calibratedLabel = 'Instrument Calibrated Range';
const normalPlaceHolder = 'Select NORMAL or ALARM';

export const HMISectionData = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'F19HmiReadingPtLabel',
                label: hmiReadingLabel,
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt',
                padding: paddingLabel,
            },
            {
                key: 'F19HmiReadingPt',
                value: formData.F19HmiReadingPt,
                xs: 2,
                isRequired: true,
                type: 'number',
                maxLength: '10',
                width: '100%',
                labelWidth: '0',
                height: '1.2rem',
                textAlign: 'center',
                borderBottom: '0'
            },
            {
                key: 'F20ProcessGaugeReadingPtLabel',
                label: processGaugeLabel,
                xs: 4,
                isLabel: true,
                labelWidth,
                fontWeight: '600',
                fontSize: '9pt',
                textAlign: 'right',
                padding: paddingLabel,
            },
            {
                key: 'F20ProcessGaugeReadingPt',
                value: formData.F20ProcessGaugeReadingPt,
                isRequired: true,
                xs: 2,
                type: 'number',
                maxLength: '10',
                height: '1.2rem',
                width: '100%',
                labelWidth: '0',
                textAlignLabel: 'end',
                marginRight: '0.5rem',
                textAlign: 'center',
                borderBottom: '0'
            }]
        },
        {
            key: 2,
            fields: [{
                key: 'F21HmiLrvLabel',
                label: 'HMI LRV',
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt',
                padding: paddingLabel,
            },
            {
                key: 'F21HmiLrv',
                value: selectedTag.DateTested ? formData?.F21HmiLrv : formData?.F21HmiLrv ?? selectedTag?.TsprHmiLrv,
                isRequired: true,
                type: 'number',
                height: '1.2rem',
                xs: 2,
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                borderBottom: '0',
                maxLength: '10',
                hasHmiError: !checkURVGreaterThanLRV(formData.F21HmiLrv, formData.F22HmiUrv)
            },
            {
                key: 'F22HmiUrvLabel',
                label: 'HMI URV',
                xs: 4,
                isLabel: true,
                labelWidth,
                fontWeight: '600',
                fontSize: '9pt',
                padding: paddingLabel,
                textAlign: 'right',
            },
            {
                key: 'F22HmiUrv',
                value: selectedTag.DateTested ? formData.F22HmiUrv : formData.F22HmiUrv ?? selectedTag?.TsprHmiUrv,
                xs: 2,
                isRequired: true,
                width: '100%',
                labelWidth: '0',
                textAlignLabel: 'end',
                height: '1.2rem',
                marginRight: '0.5rem',
                textAlign: 'center',
                type: 'number',
                borderBottom: '0',
                maxLength: '10',
                hasHmiError: !checkURVGreaterThanLRV(formData.F21HmiLrv, formData.F22HmiUrv)
            }]
        },
        {
            key: 3,
            fields: [{
                key: 'F23HmiCalculatdRangeLabel',
                label: 'HMI Calculated Range',
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt',
                padding: paddingLabel,
            },
            {
                key: 'F23HmiCalculatdRange',
                value: HMISectionCulatedRangeFormula(formData.F21HmiLrv, formData.F22HmiUrv, formData.F24HmiEngineeringUnit),
                xs: 2,
                isCalculatedNew: true,
                readOnly: true,
                inputWidth: '100%',
                labelWidth: '0',
                borderBottom: '0',
                isAutoGrow: true,
                marginRight: '0',
                borderTop: '0',
                textAlign: 'center'
            },
            {
                key: 'F24HmiEngineeringUnitLabel',
                label: EngineeringUnit.HMI_ENGINEERING_UNIT_EU,
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt',
                labelWidth,
                textAlign: 'right',
                padding: paddingLabel,
            },
            {
                key: 'F24HmiEngineeringUnit',
                value: selectedTag.DateTested ? formData?.F24HmiEngineeringUnit : formData?.F24HmiEngineeringUnit ?? selectedTag?.TsprHmiEngineeringUnits,
                xs: 2,
                inputWidth: '100%',
                labelWidth: '0%',
                textAlignLabel: 'end',
                isAutoGrow: true,
                marginRight: '0.5rem',
                textAlign: 'center',
                isRequired: true,
                maxLength: '14',
                direction: 'rtl',
            }]
        },
        {
            key: 4,
            fields: [
                {
                    key: 'F25InstrumentLrvLabel',
                    label: lrvLabel,
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingLabel,
                },
                {
                    key: 'F25InstrumentLrv',
                    value: selectedTag.DateTested ? formData?.F25InstrumentLrv : formData?.F25InstrumentLrv ?? selectedTag?.TsprInstrumentCalibratedLrv,
                    isRequired: true,
                    type: 'number',
                    xs: 2,
                    width: '100%',
                    labelWidth: '0',
                    height: '1.3rem',
                    textAlign: 'center',
                    maxLength: '10',
                    borderBottom: '0',
                    borderTop: '0',
                    hasInstError: !checkURVGreaterThanLRV(formData.F25InstrumentLrv, formData.F26InstrumentUrv)
                },
                {
                    key: 'F26InstrumentUrvLabel',
                    label: urvLabel,
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    labelWidth,
                    fontSize: '9pt',
                    textAlign: 'right',
                    padding: paddingLabel,
                },
                {
                    key: 'F26InstrumentUrv',
                    value: selectedTag.DateTested ? formData?.F26InstrumentUrv : formData?.F26InstrumentUrv ?? selectedTag?.InstrumentCalibratedUrv,
                    isRequired: true,
                    type: 'number',
                    xs: 2,
                    width: '100%',
                    labelWidth: '0%',
                    textAlignLabel: 'end',
                    height: '1.2rem',
                    marginRight: '0.5rem',
                    textAlign: 'center',
                    borderBottom: '0',
                    maxLength: '10',
                    hasInstError: !checkURVGreaterThanLRV(formData.F25InstrumentLrv, formData.F26InstrumentUrv)
                }]
        },
        {
            key: 5,
            fields: [
                {
                    key: 'F27InstrumentCalculatedRangeLabel',
                    label: 'Instrument Calculated Range',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingLabel,
                },
                {
                    key: 'F27InstrumentCalculatedRange',
                    value: HMISectionCulatedRangeFormula(formData.F25InstrumentLrv, formData.F26InstrumentUrv, formData.F28InstrumentEngineeringUnit),
                    xs: 2,
                    isCalculatedNew: true,
                    readOnly: true,
                    inputWidth: '100%',
                    labelWidth: '0%',
                    borderTop: '0',
                    textAlign: 'center',
                    marginBottom: '18px',
                    height: '2rem',
                    isAutoGrow: 'true',
                    maxLength: '55',
                    marginRight: '0',
                },
                {
                    key: 'F28InstrumentEngineeringUnitLabel',
                    label: EngineeringUnit.INSTRUMENT_ENGINEERING_UNIT_EU,
                    xs: 4,
                    isLabel: true,
                    labelWidth,
                    fontWeight: '600',
                    fontSize: '9pt',
                    textAlign: 'right',
                    padding: paddingLabel,
                },
                {
                    key: 'F28InstrumentEngineeringUnit',
                    value: selectedTag.DateTested ? formData?.F28InstrumentEngineeringUnit : formData?.F28InstrumentEngineeringUnit ?? selectedTag?.TsprInstrumentEngineeringUnits,
                    xs: 2,
                    textAlignLabel: 'end',
                    maxLength: '17',
                    inputWidth: '100%',
                    labelWidth: '0%',
                    borderTop: '0',
                    marginRight: '0.5rem',
                    isAutoGrow: true,
                    marginBottom: '18px',
                    textAlign: 'center',
                    isRequired: true,
                    direction: 'rtl',
                }]
        }]
});

export const HMISectionDataP73 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'F19HmiReadingPtLabel',
                label: hmiReadingLabel,
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt',
                padding: paddingLabel,
            },
            {
                key: 'F19HmiReadingPt',
                value: formData.F19HmiReadingPt,
                isRequired: true,
                xs: 2,
                width: '100%',
                labelWidth: '0%',
                textAlign: 'center',
                type: 'number',
                height: '1.2rem',
                maxLength: '10',
                marginRight: '0',
            },
            {
                key: 'F20ProcessGaugeReadingPtLabel',
                label: processGaugeLabel,
                xs: 4,
                isLabel: true,
                labelWidth,
                fontWeight: '600',
                fontSize: '9pt',
                textAlign: 'right',
                padding: paddingLabel,
            },
            {
                key: 'F20ProcessGaugeReadingPt',
                value: formData.F20ProcessGaugeReadingPt,
                isRequired: true,
                xs: 2,
                width: '100%',
                labelWidth: '0%',
                textAlignLabel: 'end',
                height: '1.2rem',
                marginRight: '0.5rem',
                textAlign: 'center',
                type: 'number',
                maxLength: '10',
            }]
        },
        {
            key: 2,
            fields: [
                {
                    key: 'F25InstrumentLrvLabel',
                    label: lrvLabel,
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingLabel,
                },
                {
                    key: 'F25InstrumentLrv',
                    value: selectedTag.DateTested ? formData?.F25InstrumentLrv : formData?.F25InstrumentLrv ?? selectedTag?.TsprInstrumentCalibratedLrv,
                    isRequired: true,
                    type: 'number',
                    height: '1.2rem',
                    xs: 2,
                    width: '100%',
                    labelWidth: '0%',
                    textAlign: 'center',
                    maxLength: '10',
                    borderTop: '0',
                    borderBottom: '0',
                    hasInstError: !checkURVGreaterThanLRV(formData.F25InstrumentLrv, formData.F26InstrumentUrv)
                },
                {
                    key: 'F26InstrumentUrvLabel',
                    label: urvLabel,
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    labelWidth,
                    fontSize: '9pt',
                    textAlign: 'right',
                    padding: paddingLabel,
                },
                {
                    key: 'F26InstrumentUrv',
                    value: selectedTag.DateTested ? formData?.F26InstrumentUrv : formData?.F26InstrumentUrv ?? selectedTag?.InstrumentCalibratedUrv,
                    isRequired: true,
                    type: 'number',
                    textAlignLabel: 'end',
                    xs: 2,
                    width: '100%',
                    labelWidth: '0%',
                    height: '1.2rem',
                    marginRight: '0.5rem',
                    textAlign: 'center',
                    borderTop: '0',
                    maxLength: '10',
                    borderBottom: '0',
                    hasInstError: !checkURVGreaterThanLRV(formData.F25InstrumentLrv, formData.F26InstrumentUrv)
                }]
        },
        {
            key: 3,
            fields: [
                {
                    key: 'F27InstrumentCalibratedRangeLabel',
                    label: calibratedLabel,
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingLabel,
                },
                {
                    key: 'F27InstrumentCalibratedRange',
                    value: HMISectionCulatedRangeFormula(formData.F25InstrumentLrv, formData.F26InstrumentUrv, formData.F28InstrumentEngineeringUnit),
                    xs: 2,
                    inputWidth: '100%',
                    labelWidth: '0%',
                    readOnly: true,
                    isCalculatedNew: true,
                    height: '2rem',
                    marginRight: '0',
                    textAlign: 'center',
                    borderTop: '0',
                    maxLength: '55',
                    marginBottom: '18px',
                    isAutoGrow: true
                },
                {
                    key: 'F28InstrumentEngineeringUnitLabel',
                    label: EngineeringUnit.INSTRUMENT_ENGINEERING_UNIT_EU,
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    labelWidth,
                    fontSize: '9pt',
                    textAlign: 'right',
                    padding: paddingLabel,
                },
                {
                    key: 'F28InstrumentEngineeringUnit',
                    value: selectedTag.DateTested ? formData?.F28InstrumentEngineeringUnit : formData?.F28InstrumentEngineeringUnit ?? selectedTag?.TsprInstrumentEngineeringUnits,
                    xs: 2,
                    textAlignLabel: 'end',
                    inputWidth: '100%',
                    labelWidth: '0%',
                    marginRight: '0.5rem',
                    height: '1.2rem',
                    borderTop: '0',
                    textAlign: 'center',
                    marginBottom: '15px',
                    isRequired: true,
                    maxLength: '17',
                    direction: 'rtl',
                    isAutoGrow: true,
                }]
        }

    ]

});

export const HMISectionDataP79P618P67P841P713P616 = (selectedTag, formName, formData) => {
    let hmiIndicationLabel;
    if (formName === 'P7-9') {
        hmiIndicationLabel = 'HMI Reading Prior to Test';
    } else {
        hmiIndicationLabel = 'HMI Indication Prior to Test';
    }
    return {
        rows: [{
            key: 1,
            fields: [{
                key: 'F100HmireadingPriortoTestLabel',
                label: hmiIndicationLabel,
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt',
                padding: paddingLabel,
            },
            {
                key: 'F100HmireadingPriortoTest',
                value: formData.F100HmireadingPriortoTest,
                isRequired: true,
                isDropdown: true,
                placeholder: normalPlaceHolder,
                options: [{ name: normalPlaceHolder, value: '' },
                { name: 'NORMAL', value: 'NORMAL' },
                { name: 'ALARM', value: 'ALARM' }],
                xs: 2,
                width: '100%',
                labelWidth: '0%'
            },
            {
                key: 'F28InstrumentEngineeringUnitLabel',
                label: EngineeringUnit.INSTRUMENT_ENGINEERING_UNIT_EU,
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                labelWidth,
                fontSize: '9pt',
                textAlign: 'right',
                padding: paddingLabel,
            },
            {
                key: 'F28InstrumentEngineeringUnit',
                value: selectedTag.DateTested ? formData?.F28InstrumentEngineeringUnit : formData?.F28InstrumentEngineeringUnit ?? selectedTag?.TsprInstrumentEngineeringUnits,
                xs: 2,
                textAlignLabel: 'end',
                inputWidth: '100%',
                labelWidth: '0%',
                borderTop: '0',
                marginRight: '0',
                textAlign: 'center',
                isRequired: true,
                maxLength: '17',
                direction: 'rtl',
                isAutoGrow: true,
            }]
        }]
    };
};

export const selectHMITemplateForForm = (selectedTag, formName, formData) => {
    const formNameToFunctionMap = {
        'P7-1': () => HMISectionData(selectedTag, formData),
        'P7-2': () => HMISectionData(selectedTag, formData),
        'P7-3': () => HMISectionDataP73(selectedTag, formData),
        'P7-25': () => HMISectionDataP725(selectedTag, formData),
        'P7-26': () => HMISectionDataP726(selectedTag, formData),
        'P7-4': () => HMISectionDataP74(selectedTag, formName, formData),
        'P7-4.1': () => HMISectionDataP74(selectedTag, formName, formData),
        'P7-5': () => HMISectionDataP74(selectedTag, formName, formData),
        'P6-3': () => HMISectionDataP74(selectedTag, formName, formData),
        'P6-3.2': () => HMISectionDataP74(selectedTag, formName, formData),
        'P7-7': () => HMISectionDataP77(selectedTag, formName, formData),
        'P6-5': () => HMISectionDataP77(selectedTag, formName, formData),
        'P8-3.1': () => HMISectionDataP77(selectedTag, formName, formData),
        'P6-5.2': () => HMISectionDataP77(selectedTag, formName, formData),
        'P7-8': () => HMISectionDataP78P617P712(selectedTag, formName, formData),
        'P6-17': () => HMISectionDataP78P617P712(selectedTag, formName, formData),
        'P7-12': () => HMISectionDataP78P617P712(selectedTag, formName, formData),
        'P7-28': () => HMISectionDataP78P617P712(selectedTag, formName, formData),
        'P8-7.1': () => HMISectionDataP78P617P712(selectedTag, formName, formData),
        'P7-24': () => HMISectionDataP78P617P712(selectedTag, formName, formData),
        'P7-9': () => HMISectionDataP79P618P67P841P713P616(selectedTag, formName, formData),
        'P6-18': () => HMISectionDataP79P618P67P841P713P616(selectedTag, formName, formData),
        'P6-7': () => HMISectionDataP79P618P67P841P713P616(selectedTag, formName, formData),
        'P8-4.1': () => HMISectionDataP79P618P67P841P713P616(selectedTag, formName, formData),
        'P8-10.1': () => HMISectionDataP79P618P67P841P713P616(selectedTag, formName, formData),
        'P7-13': () => HMISectionDataP79P618P67P841P713P616(selectedTag, formName, formData),
        'P6-16': () => HMISectionDataP79P618P67P841P713P616(selectedTag, formName, formData),
        'P7-10': () => HMISectionDataP710P68(selectedTag, formData),
        'P6-8': () => HMISectionDataP710P68(selectedTag, formData),
        'P8-5.1': () => HMISectionDataP851(selectedTag, formData),
        'P6-1': () => HMISectionDataP61(selectedTag, formData),
        'P8-1.1': () => HMISectionDataP61(selectedTag, formData),
        'P6-1.2': () => HMISectionDataP61(selectedTag, formData),
        'P6-4': () => HMISectionDataP61(selectedTag, formData),
        'P6-4.2': () => HMISectionDataP61(selectedTag, formData),
        'P6-2': () => HMISectionDataP61(selectedTag, formData),
        'P8-2.1': () => HMISectionDataP61(selectedTag, formData),
        'P6-2.2': () => HMISectionDataP61(selectedTag, formData),
        'P6-6': () => HMISectionDataP66(selectedTag, formData),
        'P8-17.1': () => HMISectionDataP66(selectedTag, formData),
        'P6-10': () => HMISectionDataP610P76P716P613(selectedTag, formName, formData),
        'P7-6': () => HMISectionDataP610P76P716P613(selectedTag, formName, formData),
        'P7-16': () => HMISectionDataP610P76P716P613(selectedTag, formName, formData),
        'P6-13': () => HMISectionDataP610P76P716P613(selectedTag, formName, formData),
        'P6-14.2': () => HMISectionDataP610P76P716P613(selectedTag, formName, formData),
        'P6-14': () => HMISectionDataP610P76P716P613(selectedTag, formName, formData),
        'P7-31': () => HMISectionDataP610P76P716P613(selectedTag, formName, formData),
        'P6-23.2': () => HMISectionDataP610P76P716P613(selectedTag, formName, formData),
        'P7-27': () => HMISectionDataP610P76P716P613(selectedTag, formName, formData),
        'C1-2': () => HMISectionDataP610P76P716P613(selectedTag, formName, formData),
        'P6-30': () => HMISectionDataP610P76P716P613(selectedTag, formName, formData),
        'P8-6.1': () => HMISectionDataP861andP69(selectedTag, formData),
        'P6-9': () => HMISectionDataP861andP69(selectedTag, formData),
        'P8-11.1': () => HMISectionDataP861andP69(selectedTag, formData),
        'M6-13': () => HMISectionDataM613(selectedTag, formData),
        'M6-3': () => HMISectionDataMseries(selectedTag, formData, formName),
        'M6-4': () => HMISectionDataMseries(selectedTag, formData, formName),
        'M6-5': () => HMISectionDataMseries(selectedTag, formData, formName),
        'M6-6': () => HMISectionDataMseries(selectedTag, formData, formName),
        'M6-7': () => HMISectionDataMseries(selectedTag, formData, formName),
        'M6-9': () => HMISectionDataMseries(selectedTag, formData, formName),
        'M6-10': () => HMISectionDataMseries(selectedTag, formData, formName),
        'M6-12': () => HMISectionDataMseries(selectedTag, formData, formName),
        'M3-1': () => HMISectionDataM31P723(selectedTag, formData),
        'P7-23': () => HMISectionDataM31P723(selectedTag, formData),
        'M6-8': () => HMISectionDataM68(selectedTag, formData),
        'M6-1': () => HMISectionDataM611(selectedTag, formData),
        'M6-11': () => HMISectionDataM611(selectedTag, formData),
        'M6-14': () => HMISectionDataM611(selectedTag, formData),
        'M6-15': () => HMISectionDataM611(selectedTag, formData),
        'C1-1': () => HMISectionDataC11C13C132C14(selectedTag, formData),
        'C1-3': () => HMISectionDataC11C13C132C14(selectedTag, formData),
        'C1-3.2': () => HMISectionDataC11C13C132C14(selectedTag, formData),
        'C1-4': () => HMISectionDataC11C13C132C14(selectedTag, formData),
        'P6-15': () => HMISectionDataP615(selectedTag, formData),
        'P7-32': () => HMISectionDataP732(selectedTag, formData),
        'P7-33': () => HMISectionDataP733P620P611P717(selectedTag, formName, formData),
        'P6-20': () => HMISectionDataP733P620P611P717(selectedTag, formName, formData),
        'P6-11': () => HMISectionDataP733P620P611P717(selectedTag, formName, formData),
        'P7-17': () => HMISectionDataP733P620P611P717(selectedTag, formName, formData),
        'P8-9.1': () => HMISectionDataP891(selectedTag, formData),
        'P7-19': () => HMISectionDataP891(selectedTag, formData),
        'M6-16': () => HMISectionDataM616(selectedTag, formData),
        'M7-1': () => HMISectionDataM71(selectedTag, formData),
        'P7-20': () => HMISectionDataP720(selectedTag, formData),
        'P6-26': () => HMISectionDataP720(selectedTag, formData),
        'P7-21': () => HMISectionDataP721(selectedTag, formData),
        'P7-11': () => HMISectionDataP711(selectedTag, formName, formData),
        'P6-22': () => HMISectionDataP711(selectedTag, formName, formData),
        'P7-22': () => HMISectionDataP711(selectedTag, formName, formData),
        'P7-15': () => HMISectionDataP711(selectedTag, formName, formData),
        'P7-14': () => HMISectionDataP711(selectedTag, formName, formData),
        'P6-31': () => HMISectionDataP711(selectedTag, formName, formData),
        'P7-18': () => HMISectionDataP711(selectedTag, formName, formData),
        'P6-27': () => HMISectionDataP711(selectedTag, formName, formData),
        'P6-12': () => HMISectionDataP711(selectedTag, formName, formData),
        'P7-29': () => HMISectionDataP711(selectedTag, formName, formData),
        'P8-8.1': () => HMISectionDataP711(selectedTag, formName, formData),
        'P8-12.1': () => HMISectionDataP711(selectedTag, formName, formData),
        'P8-13.1': () => HMISectionDataP711(selectedTag, formName, formData),
        'P7-30': () => HMISectionDataP711(selectedTag, formName, formData),
        'P8-14.1': () => HMISectionDataP8141(formData),
        'P6-29': () => HMISectionDataP8141(formData),
        'S7-2': () => HMISectionDataS72(selectedTag, formData),
        'S7-1': () => ValveSectionDataS71(selectedTag, formData),
        'S7-3': () => HMISectionDataS73(selectedTag, formData),
        'S7-4': () => HMISectionDataS72(selectedTag, formData),
        'P6-25': () => HMISectionDataP625(formData),
    };
    let filterViewData = { rows: [] };
    const functionToExecute = formNameToFunctionMap[formName];
    if (functionToExecute) {
        filterViewData = functionToExecute();
    }
    return filterViewData;
};
